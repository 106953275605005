import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {PaginationHeader} from "../../@interfaces/PaginationHeader";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";


import AccountsService from "../../services/AccountsService";
import AccountsFilters from "../../components/AccountsFilters/AccountsFilters";
import AccountListView from "../../views/AccountsListView/AccountListView";
import {AccountInterface} from "../../@interfaces/AccountInterface";
import PaginationWithPageSizeSelector from "../../components/Pagination/PaginationWithSizeSelectorComponent";
import {parseResponseHeaders} from "../../utils/ResponseWrapper";
import AuthenticationService from "../../services/AuthenticationService";


interface AccountsPerPage {
    page: number;
    items: AccountInterface[];
}

export function AccountsListPage() {
    const {t} = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [showInitializerFormModal, setShowInitializerFormModal] = useState(false);
    const [accounts, setAccounts] = useState<AccountsPerPage[]>([]);
    const [currentPageAccountsData, setCurrentPageAccountsDatas] = useState<AccountInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageAccounts(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageAccounts(pageNumber);
            } else {
                fetchAccounts({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const getCurrentPageAccounts = (pageNumber: number): AccountsPerPage => {

        return accounts.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageAccounts = (pageNumber: number) => {
        try {
            const accountsPage = getCurrentPageAccounts(pageNumber);
            setCurrentPageAccountsDatas(accountsPage.items)
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));
            setAccounts([]);
            fetchAccounts({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = (values: any) => {
        try {
            fetchAccounts({currentPage: 1, pageSize: itemsPerPage}, values);
        } catch (e: any) {
            console.error('error on line ', e);
        }
    }


    const fetchAccounts = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);

            const response = await AccountsService.retrieveAccounts({pagination: pagingData, filters: filter});
            const {total_pages, page_size, total_count} = parseResponseHeaders(response.headers);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setAccounts(prevData => [...prevData, {
                page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                items: response.data
            }]);
            setCurrentPageAccountsDatas(response.data)

            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events profile:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
          const login = async () => {
            try {
                await AuthenticationService.login({
                    identifier: process.env.REACT_APP_API_IDENTIFIER ?? "",
                    secret: process.env.REACT_APP_API_SECRET ?? ""
                });
            } catch (error) {
                console.error('Error logging in:', error);
            }
        };

        login();
        fetchAccounts();
    }, []);

    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}
            <AccountsFilters setFiltersValue={applyFilters}/>
            <PaginationWithPageSizeSelector itemsPerPage={itemsPerPage} totalCount={totalCount}
                                            currentPage={currentPage} totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                            handlePageSizeChange={handlePageSizeChange}/>

            <AccountListView accounts={currentPageAccountsData}/>

        </>
    );
}

export default AccountsListPage;