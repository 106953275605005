import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {CompanyInterface} from "../../@interfaces/CompanyInterface";
import {Button, Form, Table} from "react-bootstrap";


interface CompanyListViewProps {
    companies: CompanyInterface[];
}

function CompanyListView({companies}: CompanyListViewProps) {
    const {t} = useTranslation();
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(companies.map(company => false));
    useEffect(() => {
        setSelectedCheckboxes(companies.map(() => false));
        setSelectedAll(false);
    }, [companies]);
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(companies.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };
    return (<>
        {<Table striped responsive>
            <thead>
            <tr>
                <th>#</th>
                <th>
                    <Form.Check
                        type="checkbox"
                        id={`select-all-checkbox`}
                        checked={selectedAll}
                        onChange={handleSelectAll}
                    />
                </th>
                <th>{t('common.name')}</th>
                <th>{t('common.phone')}</th>
                <th>{t('common.email')}</th>
                <th>{t('common.businessName')}</th>
                <th>{t('common.createdAt')}</th>
                <th>{t('common.action')}</th>
            </tr>
            </thead>
            <tbody>

            {companies.map((company: CompanyInterface, index) => (
                <tr key={company.id}>
                    <td>{index + 1}</td>
                    <td>
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`checkbox-${company.id}`}
                            checked={selectedCheckboxes[index] || false}
                            onChange={() => handleSelectCheckbox(index)}
                        />
                    </td>
                    <td>{company.name}</td>
                    <td>{company.phone_number}</td>
                    <td>{company.email_address}</td>
                    <th>{company.business_name}</th>
                    <td>{company.created_at}</td>
                    <td className="justify-content-between">
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-eye-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-pencil-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-trash"></i>
                        </Button>
                    </td>
                </tr>
            ))
            }
            </tbody>
        </Table>}
    </>)
}

export default CompanyListView;