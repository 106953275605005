import axiosClient from "./AxiosInstance";


import {PaginationHeader} from "../@interfaces/PaginationHeader";
import {isEmpty} from "../utils/utils";
import {CompanyInterface} from "../@interfaces/CompanyInterface";

// Define the expected response structure
interface CompaniesResponse {
    data: CompanyInterface[];
    headers: any;
}

const emptyFilter = {
    pagination: {
        currentPage: 1,
        pageSize: 0,
    },
    filters: null
}


export default class CompaniesService {
    static async retrieveCompanies({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    } = emptyFilter): Promise<CompaniesResponse> {
        try {
            const formattedFilters = CompaniesService.formatFiltersToQueryString(filters)
            const response = await axiosClient.get(`/companies${!isEmpty(formattedFilters) ? `?${formattedFilters}` : ''}`, {
                headers: {
                    'page': pagination.currentPage,
                    'page-size': pagination.pageSize
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }

    static async retrieveCompany(uuid: number) {
        try {
            const response = await axiosClient.get(`/companies/${uuid}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    static formatFiltersToQueryString(filters: any) {
        if (!isEmpty(filters)) {
            const filter = {
                names: filters.names?.map((val: any) => val.text),
            }
            // Remove all undefined keys
            const filteredFilter = Object.fromEntries(
                Object.entries(filter).filter(([key, value]) => value !== undefined && value !== null && value !== '' && value.length != 0)
            );
            return new URLSearchParams(Object.entries(filteredFilter)).toString();
        }

        return filters;
    }

}