// AuthRoutes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../pages/AuthenticationPages/LoginPage/LoginPage';
import Callback from '../pages/AuthenticationPages/LoginPage/Callback';
import RegistrationPage from "../pages/AuthenticationPages/RegistrationPage/RegistrationPage";

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path="login" element={<LoginPage />} />
            <Route path="callback" element={<Callback />} />
            <Route path="registration" element={<RegistrationPage />} />
        </Routes>
    );
};

export default AuthRoutes;
