
export const handleResponse = (response:any) => {
  // Customize response handling if needed
  return response;
};

export const handleError = (error:any) => {
  if (error.response) {
    // Server responded with a status other than 2xx
    return {
      message: error.response.data.message || 'An error occurred',
      data: error.response.data?.detail || 'An error occurred',
      status: error.response.status,
    };
  } else if (error.request) {
    // No response was received from the server
    return {
      message: 'No response received from the server',
      status: 500,
    };
  } else {
    // Something else happened while setting up the request
    return {
      message: error.message,
      status: 500,
    };
  }
};

export const parseResponseHeaders = (headers:any) => {
  const total_pages = Number.isNaN(Number(headers.total_pages)) ? 0 : Number(headers.total_pages);
  const page_size = Number.isNaN(Number(headers.page_size)) ? 0 : Number(headers.page_size);
  const total_count = Number.isNaN(Number(headers.total_count)) ? 0 : Number(headers.total_count);

  return { total_pages, page_size, total_count };
};
