const companyFormTranslation = {
    companyName: 'Company Name',
    companyHeadOfficeCountry: 'Head Office Country',
    companyAddress: 'Address',
    companyPostalCode: 'Postal Code',
    companyCity: 'City',
    companyDistrict: 'Region',
    localOfficeAddress: 'Local Office Postal Address',
    proofsDownloadable: "Upload your proof documents ({{value}})",
    uploadRequired: "This field is required",
    entertainmentLicenceReceiptNumber: "Entertainment License Receipt Number",
    entertainmentReceiptNumberInvalid: "This field must contain 40 characters",
    businessName: "Business Name",
    businessSector: "Business Sector",
    atoutFranceRegistrationNumber: "Atout France Registration Number",
    licenceCategory: "License Category (Type of Activity)",
    licenseCategory: "Category {{value}}",
    companyAdditionalAddress:"Additional Address",


}
export default companyFormTranslation;
