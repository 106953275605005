import {Card, Col, Container, Row} from "react-bootstrap";
import './SettingsPage.scss'

function SettingsPage() {
    const settingsData = [
        {title: "Profil", icon: "bi-person", description: "Modifier votre profil"},
        {
            title: "Informations Légales",
            icon: "bi-file-earmark-text",
            description: "Renseignez vos informations légales"
        },
        {title: "ITR (Interface Temps Réel)", icon: "bi-graph-up", description: "Gérez vos ITRs"},
        {title: "Clés APIs", icon: "bi-graph-up", description: "Consultez vos clés apis"},
        {title: "Paiement", icon: "bi-credit-card", description: "Configurez les options de paiement"},
        {title: "Abonnements", icon: "bi-credit-card", description: "Mon abonnement"},
    ];

    return (
        <Container>
            <h2 className="my-4 text-center">Paramètres</h2>
            <Row className="g-4">
                {settingsData.map((setting, index) => (
                    <Col xs={12} sm={6} md={6} lg={6} key={index}>
                        <Card className="h-100 shadow-sm setting-cards">
                            <Card.Body className="text-center">
                                <i className={`bi ${setting.icon} mb-3`} style={{fontSize: "2rem"}}></i>
                                <Card.Title>{setting.title}</Card.Title>
                                <Card.Text>{setting.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    )

}

export default SettingsPage;