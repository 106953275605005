import {toast, ToastPosition} from 'react-hot-toast';

type ToastType = 'success' | 'error' | 'info' | 'loading';

const showToast = (message: string, type: ToastType = 'info', duration: number = 3000,position: ToastPosition = 'top-center') => {
    try{
          switch (type) {
        case 'success':
            toast.success(message, { duration,position });
            break;
        case 'error':
            toast.error(message, { duration,position });
            break;
        default:
            toast(message, { duration,position });
            break;
    }
    }catch(e){
        console.log(e)
    }

};

export default showToast;
