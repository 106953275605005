import {Col, Form, FormGroup, InputGroup, Row} from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import fr from "react-phone-number-input/locale/fr.json";
import en from "react-phone-number-input/locale/en.json";
import React, {Fragment} from "react";
import {FormikErrors, FormikTouched} from "formik";
import {LegalInformationInterface} from "../../../../@interfaces/LegalInformation";
import Select from "react-select";
import {OptionType} from "../../../../@interfaces/OptionType";
import UploadComponent from "../../../upload/UploadComponent";
import "./LegalInformation.scss"
import {ATTACHMENT_TYPE_BY_LEGAL_STATUS, BUSINESS_SECTORS, COMPANY_LEGAL_STATUS_LIST} from "../../../../utils/constants";
import countries from "../../../../utils/countries";

const attachmentByLegalStatus = ATTACHMENT_TYPE_BY_LEGAL_STATUS;

const legalStatusOptions = COMPANY_LEGAL_STATUS_LIST;
const renderLegalStatusOptions = (option: string) => {
    return <option value={option}>{option}</option>;
};
const businessSectorsOption: OptionType[] = BUSINESS_SECTORS;

interface LegalInformationProps {
    handleSubmit(): void,

    setAttachments: (value: any) => void,
    setFieldValue: (field: string, value: any) => void;
    valuesLegalInformation: LegalInformationInterface,
    touched: FormikTouched<LegalInformationInterface>,
    errors: FormikErrors<LegalInformationInterface>,
    t: (key: string) => string;
    i18n: any
}

function LegalInformation({
                              handleSubmit,
                              setAttachments,
                              setFieldValue,
                              valuesLegalInformation,
                              touched,
                              errors,
                              t, i18n
                          }: LegalInformationProps) {
    const getCountryName = (countryCode: string): string => {
        return i18n.t(`country.${countryCode}`);
    };
    const handleLegalStatusChange = (value: string) => {
        /*let requiredAttachments: string[];
        requiredAttachments = attachmentByLegalStatus.find((data) => data.label === value)?.value ?? []
        setAttachments(requiredAttachments);*/
        console.log('');
    };

    return (

        <Row>
            <fieldset className="form-group border p-3">
                <legend className="w-auto px-2"> {t('common.legalInformation')}</legend>
                <Row className="mb-3">
                    <Col md={8}>
                        <Row className="mb-2">
                            <Col md={12} lg={12} sm={12}>
                                <Form.Group controlId="companyFormName">
                                    <Form.Label>{t('companyForm.companyName')}</Form.Label>
                                    <Form.Control type="text"
                                                  placeholder={t('companyForm.companyName')}
                                                  aria-label={t('companyForm.companyName')}
                                                  name="name"
                                                  value={valuesLegalInformation.name || ''}
                                                  onChange={(e) => setFieldValue('legalInformation.name', e.target.value)}
                                                  isInvalid={touched?.name && !!errors?.name}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={6} lg={6} sm={12}>
                                <Form.Group controlId="companyFormBusinessName">
                                    <Form.Label>{t('companyForm.businessName')}</Form.Label>
                                    <Form.Control type="text"
                                                  placeholder={t('companyForm.businessName')}
                                                  aria-label={t('companyForm.businessName')}
                                                  name="name"
                                                  value={valuesLegalInformation.businessName || ''}
                                                  onChange={(e) => setFieldValue('legalInformation.businessName', e.target.value)}
                                                  isInvalid={touched?.businessName && !!errors?.businessName}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.businessName}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="companyFormBusinessSector">
                                    <Form.Label>{t('companyForm.businessSector')}</Form.Label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        onChange={(selected) => setFieldValue('legalInformation.businessSector', selected)}
                                        isMulti
                                        options={businessSectorsOption}
                                        value={valuesLegalInformation?.businessSector}
                                    />
                                    {touched?.businessSector && errors?.businessSector && (
                                        <div className="invalid-feedback d-block">
                                            {errors?.businessSector as string}
                                        </div>
                                    )}
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col md={12} lg={12} sm={12}>
                                <Form.Group controlId="companyFormLegalStatus">
                                    <Form.Label>Raison social</Form.Label>
                                    <Form.Select aria-label="Raison sociale"
                                                 value={valuesLegalInformation.legalStatus || ''}
                                                 name="legalStatus"
                                                 onChange={(e) => {
                                                     setFieldValue('legalInformation.legalStatus', e.target.value);
                                                     handleLegalStatusChange(e.target.value);
                                                 }}
                                                 isInvalid={touched?.legalStatus && !!errors?.legalStatus}>
                                        <option>{t('common.pleaseSelect')}.</option>
                                        {legalStatusOptions.map((opt) => (
                                            <Fragment key={opt}>{renderLegalStatusOptions(opt)}</Fragment>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.legalStatus}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} lg={4} sm={12} className="company-upload-container">
                        <UploadComponent label="Logo" maxSize="2Mo"/>
                    </Col>

                </Row>
                <Row className="mb-2">
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group controlId="companyFormCountry">
                            <Form.Label>{t('companyForm.companyHeadOfficeCountry')}</Form.Label>
                            <Form.Select aria-label={t('companyForm.companyHeadOfficeCountry')}

                                         value={valuesLegalInformation.headOfficeCountry || ''}
                                         name="headOfficeCountry"
                                         onChange={(e) => setFieldValue('legalInformation.headOfficeCountry', e.target.value)}
                                         isInvalid={touched?.headOfficeCountry && !!errors?.headOfficeCountry}>
                                <option>{t('common.pleaseSelect')}</option>
                                {countries.map((country) => (
                                    <option key={country.code} value={country.code}>
                                        {getCountryName(country.code)}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors?.headOfficeCountry}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6} lg={6} sm={12}>
                        <Form.Group controlId="companyFormRegion">
                            <Form.Label>{t('companyForm.companyDistrict')}</Form.Label>
                            <Form.Select aria-label={t('companyForm.companyDistrict')}

                                         name="headOfficeRegion"
                                         value={valuesLegalInformation.headOfficeRegion || ''}
                                         onChange={(e) => setFieldValue('legalInformation.headOfficeRegion', e.target.value)}
                                         isInvalid={touched?.headOfficeRegion && !!errors?.headOfficeRegion}
                            >
                                <option>{t('common.pleaseSelect')}</option>
                                <option value="NA">Novelle Aquitaine</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors?.headOfficeRegion}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={8} lg={0} sm={12} className="mb-3">
                        <Form.Group controlId="companyFormAdress">
                            <Form.Label>{t('companyForm.companyAddress')}</Form.Label>
                            <Form.Control type="text" placeholder={t('companyForm.companyAddress')}
                                          aria-label={t('companyForm.companyAddress')}
                                          name="headOfficeAddress"
                                          value={valuesLegalInformation.headOfficeAddress || ''}
                                          onChange={(e) => setFieldValue('legalInformation.headOfficeAddress', e.target.value)}
                                          isInvalid={touched?.headOfficeAddress && !!errors?.headOfficeAddress}/>
                            <Form.Control.Feedback type="invalid">
                                {errors?.headOfficeAddress}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                     <Col md={4} lg={4} sm={12} className="mb-3">
                        <Form.Group controlId="companyAdditionalAddress">
                            <Form.Label>{t('companyForm.companyAdditionalAddress')}</Form.Label>
                            <Form.Control type="text" placeholder={t('companyForm.companyAdditionalAddress')}
                                          aria-label={t('companyForm.companyAdditionalAddress')}
                                          name="headOfficeAdditionalAddress"
                                          value={valuesLegalInformation.headOfficeAdditionalAddress || ''}
                                          onChange={(e) => setFieldValue('legalInformation.headOfficeAdditionalAddress', e.target.value)}
                                          isInvalid={touched?.headOfficeAdditionalAddress && !!errors?.headOfficeAdditionalAddress}/>
                            <Form.Control.Feedback type="invalid">
                                {errors?.headOfficeAdditionalAddress}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group controlId="companyFormPostalCode">
                            <Form.Label>{t('companyForm.companyPostalCode')}</Form.Label>
                            <Form.Control type="text" placeholder={t('companyForm.companyPostalCode')}
                                          aria-label={t('companyForm.companyPostalCode')}
                                          name="headOfficePostalCode"
                                          value={valuesLegalInformation.headOfficePostalCode || ''}
                                          onChange={(e) => setFieldValue('legalInformation.headOfficePostalCode', e.target.value)}
                                          isInvalid={touched?.headOfficePostalCode && !!errors?.headOfficePostalCode}/>
                            <Form.Control.Feedback type="invalid">
                                {errors?.headOfficePostalCode}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group controlId="companyFormPostalCity">
                            <Form.Label>{t('companyForm.companyCity')}</Form.Label>
                            <Form.Control type="text" placeholder={t('companyForm.companyCity')}
                                          aria-label={t('companyForm.companyCity')}
                                          name="headOfficeCity"
                                          value={valuesLegalInformation.headOfficeCity || ''}
                                          onChange={(e) => setFieldValue('legalInformation.headOfficeCity', e.target.value)}
                                          isInvalid={touched?.headOfficeCity && !!errors?.headOfficeCity}/>
                            <Form.Control.Feedback type="invalid">
                                {errors?.headOfficeCity}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group controlId="companyFormEmail">
                            <Form.Label>{t('common.email')}</Form.Label>
                            <Form.Control type="email" placeholder={t('common.email')}
                                          aria-label={t('common.email')}
                                          name="email"
                                          value={valuesLegalInformation.email || ''}
                                          onChange={(e) => setFieldValue('legalInformation.email', e.target.value)}
                                          isInvalid={touched?.email && !!errors?.email}/>
                            <Form.Control.Feedback type="invalid">
                                {errors?.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        <Form.Group controlId="companyFormPhoneCode">
                            <Form.Label>{t('common.callSign')}</Form.Label>
                            <PhoneInput
                                className="form-control"
                                aria-label={t('common.callSign')}
                                placeholder={t('common.pleaseEnterPhoneNumber')}
                                labels={i18n.resolvedLanguage === "fr" ? fr : en}
                                defaultCountry="FR"
                                name="phoneNumber"
                                value={valuesLegalInformation.phoneNumber || ''}
                                onChange={(value) => setFieldValue('legalInformation.phoneNumber', value || '')}
                            />

                            {touched?.phoneNumber && errors?.phoneNumber && (
                                <div className="invalid-feedback d-block">
                                    {errors?.phoneNumber as string}
                                </div>
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={12} lg={12} sm={12}>
                        <FormGroup controlId="companyFormWebsite">
                            <Form.Label>{t('common.website')}</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon">
                                    www
                                </InputGroup.Text>
                                <Form.Control aria-describedby="website url addon"
                                              aria-label={t('common.website')}
                                              name="websiteUrl"
                                              value={valuesLegalInformation.websiteUrl || ''}
                                              onChange={(e) => setFieldValue('legalInformation.websiteUrl', e.target.value)}
                                              isInvalid={touched?.websiteUrl && !!errors?.websiteUrl}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.websiteUrl}
                                </Form.Control.Feedback>
                            </InputGroup>

                        </FormGroup>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md={valuesLegalInformation.headOfficeCountry === "FR" ? 6 : 12} lg={valuesLegalInformation.headOfficeCountry === "FR" ? 6 : 12} sm={12}>
                        <FormGroup controlId="companyFormTVA">
                            <Form.Label>N° {t('common.vat')}</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="vat-addon">
                                    {t('common.vat')}
                                </InputGroup.Text>
                                <Form.Control aria-describedby="vat-addon" aria-label={t('common.vat')}
                                              name="vatNumber"
                                              value={valuesLegalInformation.vatNumber || ''}
                                              onChange={(e) => setFieldValue('legalInformation.vatNumber', e.target.value)}
                                              isInvalid={touched?.vatNumber && !!errors?.vatNumber}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.vatNumber}
                                </Form.Control.Feedback>
                            </InputGroup>

                        </FormGroup>
                    </Col>
                    {valuesLegalInformation.headOfficeCountry === "FR" &&  <Col md={6} lg={6} sm={12}>
                        <FormGroup controlId="companyFormSIRET">
                            <Form.Label>N° {t('common.siret')}</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Text id="siret-addon">
                                    {t('common.siret')}
                                </InputGroup.Text>
                                <Form.Control aria-describedby="siret-addon"
                                              aria-label={t('common.siret')}
                                              name="siretNumber"
                                              value={valuesLegalInformation.siretNumber || ''}
                                              onChange={(e) => setFieldValue('legalInformation.siretNumber', e.target.value)}
                                              isInvalid={touched?.siretNumber && !!errors?.siretNumber}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors?.siretNumber}
                                </Form.Control.Feedback>
                            </InputGroup>

                        </FormGroup>
                    </Col>}


                </Row>


            </fieldset>
        </Row>)
}

export default LegalInformation;