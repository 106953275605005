import axiosClient from "./AxiosInstance";

const inseApiBaseUrl = "https://api.insee.fr/entreprises/sirene/V3.11"
const inseBearToken ="76b01dfe-bc33-398c-bde1-dca45785e146"

export default class ComplianceService {
    static async verifySiretNumber(siret:string) {
        try {

            const response = await axiosClient.get(`${inseApiBaseUrl}/siret/${siret}`, {
                headers: {
                    'Authorization': `Bearer ${inseBearToken}`,
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }

       static async verifySirenNumber(siret:string) {
        try {

            const response = await axiosClient.get(`${inseApiBaseUrl}/siren/${siret}`, {
                headers: {
                    'Authorization': `Bearer ${inseBearToken}`,
                }
            })
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }

}