import React, {useState} from 'react';
import {Badge, Button, Card, Carousel, Col, Container, Form, ListGroup, Row, Tab, Tabs} from 'react-bootstrap';
import {EventInterface} from "../../@interfaces/EventInterface";
import './EventDetails.scss'
import {useTranslation} from "react-i18next";

interface EventDetailsViewProps {
    event: EventInterface
}

function EventDetailsView({event}: EventDetailsViewProps): JSX.Element {
    const {t, i18n} = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');

    const rawData = event

    const handleSearchChange = (event: any) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = Object.entries(rawData).filter(([key, value]) => {
        if (Array.isArray(value)) {
            return value.some(item => {
                if (typeof item === 'object' && item !== null) {
                    return Object.entries(item).some(([objKey, objValue]) =>
                        `${objKey}: ${objValue}`.toLowerCase().includes(searchQuery.toLowerCase())
                    );
                }
                // Si ce n'est pas un objet, faire une recherche classique
                return `${item}`.toLowerCase().includes(searchQuery.toLowerCase());
            });
        }
        if (typeof value === 'object' && value !== null) {
            return Object.entries(value).some(([objKey, objValue]) =>
                `${objKey}: ${objValue}`.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        // Recherche normale si ce n'est pas un tableau
        return `${key}: ${value}`.toLowerCase().includes(searchQuery.toLowerCase());
    });


    return (
        <Container className="my-4">
            <Row className="justify-content-center">
                <Col md={12}>
                    <Tabs defaultActiveKey="event" id="event-details-tabs" className="mb-3">
                        <Tab eventKey="event" title={t('common.eventDetails')} className="event-details-tabs-pannel">
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col md={6}>
                                            <Carousel indicators={false}>
                                                {rawData.image_urls.map((image, index) => (
                                                    <Carousel.Item key={index}>
                                                        <img
                                                            className="d-block w-100"
                                                            src={image}
                                                            alt={`Slide ${index}`}
                                                        />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        </Col>
                                        <Col md={6}>
                                            <Card.Title><strong>{rawData.name}</strong></Card.Title>
                                            <Card.Text
                                                className="event-description-text-truncate">{rawData.description}</Card.Text>
                                            <div className="mb-3">
                                                {rawData.tags.map((tag, index) => (
                                                    <Badge bg="secondary" className="me-2"
                                                           key={index}>{tag.name}</Badge>
                                                ))}
                                            </div>
                                            <p><strong>{t('common.venue')} : </strong> {rawData.venue ? `${rawData.venue?.name} (${rawData.venue?.address.address},${rawData.venue?.address.postal_code},${rawData.venue?.address.city}) ` : "N/A"} </p>
                                            <Button
                                                variant="primary"
                                                href={rawData.marketplace_url}
                                                target="_blank"
                                            >
                                                {t('common.linkToTicketing')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Tab>
                        <Tab eventKey="raw" title={t('common.eventBruteDetails')} className="event-details-tabs-pannel">
                            <Card>
                                <Card.Body>
                                    <Form.Control
                                        type="text"
                                        placeholder="Rechercher dans les données brutes..."
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        className="mb-3"
                                    />
                                    <ListGroup variant="flush">
                                        {filteredData.map(([key, value], index) => (
                                            <ListGroup.Item key={index}>
                                                <strong>{key} : </strong>
                                                {Array.isArray(value)
                                                    ? value.map(item =>
                                                        typeof item === 'object' && item !== null
                                                            ? JSON.stringify(item)
                                                            : item
                                                    ).join(', ')
                                                    : (typeof value === 'object' && value !== null ? JSON.stringify(value, null, 2) : value)}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>


                                </Card.Body>
                            </Card>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
}

export default EventDetailsView;
