import axiosClient from "./AxiosInstance";

import {PaginationHeader} from "../@interfaces/PaginationHeader";
import {TagInterface} from "../@interfaces/TagInterface";

interface TagsResponse {
    data: TagInterface[];
    headers: any;
}

const emptyFilter = {pagination: {
    currentPage: 1,
    pageSize: 0,
    },
    filters:null
}

export default class TagsService {
    static async retrieveTags({pagination, filters}: {
        pagination: PaginationHeader,
        filters: any
    } = emptyFilter): Promise<TagsResponse> {
        try {
            const response = await axiosClient.get("/tags", {});
            return {
                data: response.data,
                headers: response.headers
            };
        } catch (error) {
            throw error;
        }

    }
}