import {Badge, Button, Dropdown, Form, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import EventsService from "../../services/EventsService";
import {EventInterface} from "../../@interfaces/EventInterface";
import AuthenticationService from "../../services/AuthenticationService";

import EventSharedWithMe from "../../views/EventsView/EventsSharedWithMe/EventsSharedWithMe";
import MyEvents from "../../views/EventsView/MyEvents/MyEvents";
import EventsFilters from "../../components/Event/EventsFilters/EventsFilters";

import './EventsPage.scss';
import {PaginationHeader} from "../../@interfaces/PaginationHeader";
import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";
import {VenueInterface} from "../../@interfaces/VenueInterface";
import {TagInterface} from "../../@interfaces/TagInterface";
import VenuesService from "../../services/VenuesService";
import {useTranslation} from "react-i18next";
import TagsService from "../../services/TagsService";
import {parseResponseHeaders} from "../../utils/ResponseWrapper";
import PaginationWithPageSizeSelector from "../../components/Pagination/PaginationWithSizeSelectorComponent";
import DownloadCSV from "../../utils/exportDataToCsv";
import DownloadJSON from "../../utils/exportDataToJSON";
import {FaFile, FaFileCode, FaFileCsv, FaFilePdf} from "react-icons/fa";
import downloadJSON from "../../utils/exportDataToJSON";
import downloadCSV from "../../utils/exportDataToCsv";
import downloadXML from "../../utils/exportDataToXml";
import showToast from "../../utils/showToast";
import { Toaster } from 'react-hot-toast';

interface EventsPerPage {
    page: number;
    items: EventInterface[];
}

function EventsPage() {
    const {t, i18n} = useTranslation();
    const [viewMode, setViewMode] = useState<string>('list');
    const [isLoading, setIsLoading] = useState(false);
    const [events, setEvents] = useState<EventsPerPage[]>([]);
    const [myEvents, setMyEvents] = useState<EventInterface[]>([]);
    const [venuesList, setVenuesList] = useState<VenueInterface[]>([]);
    const [tagsList, setTagsList] = useState<TagInterface[]>([]);
    const [sharedWithMeEvents, setSharedWithMeEvents] = useState<EventInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageEvents(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageEvents(pageNumber);
            } else {
                fetchEvents({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const countEventsByOwned = () => {
        return events.reduce((acc, currentValue) => {
            return {
                ownedByMe: acc.ownedByMe + currentValue.items.filter(value => value.owned).length,
                shareWithMe: acc.shareWithMe + currentValue.items.filter(value => !value.owned).length
            }

        }, {ownedByMe: 0, shareWithMe: 0})
    }

    const getCurrentPageEvents = (pageNumber: number): EventsPerPage => {

        return events.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageEvents = (pageNumber: number) => {
        try {
            const eventsPage = getCurrentPageEvents(pageNumber);
            const myEvents = eventsPage.items.filter((val: EventInterface) => val.owned);
            const sharedWithMeEvents = eventsPage.items.filter((val: EventInterface) => !val.owned);
            setMyEvents(myEvents);
            setSharedWithMeEvents(sharedWithMeEvents);

        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));

            setEvents([]);
            fetchEvents({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = (values: any) => {
        try {
            fetchEvents({currentPage: 1, pageSize: itemsPerPage}, values);
        } catch (e: any) {
            console.error('error on line ', e);
        }
    }

    const fetchVenues = async () => {
        try {
            setIsLoading(true);
            const response = await VenuesService.retrieveVenues();
            setVenuesList(response.data)
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.error('error on line ', e);
        }
    }
    const fetchTags = async () => {
        try {
            setIsLoading(true);
            const response = await TagsService.retrieveTags();
            setTagsList(response.data)
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.error('error on line ', e);
        }
    }

    const fetchEvents = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);

            const response = await EventsService.retrieveEvents({pagination: pagingData, filters: filter});
            const {total_pages, page_size, total_count} = parseResponseHeaders(response.headers);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setEvents(prevData => [...prevData, {
                page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                items: response.data
            }]);
            const myEvents = response.data.filter((val: EventInterface) => val.owned);
            const sharedWithMeEvents = response.data.filter((val: EventInterface) => !val.owned);
            setMyEvents(myEvents);
            setSharedWithMeEvents(sharedWithMeEvents);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events profile:', error);
            setIsLoading(false);
        }
    };

    const eventsForDownload = (type: string) => {
        const reduced = events.reduce((acc: EventInterface[], currentValue) => {
            const items = Array.isArray(currentValue.items) ? currentValue.items : [];
            return [...acc, ...items];
        }, []);
        switch (type) {
            case 'json':
                downloadJSON({data: reduced, fileName: `events_export_${(new Date()).toISOString()}`});
                break;
            case 'csv':
                downloadCSV({data: reduced, fileName: `events_export_${(new Date()).toISOString()}`});
                break;
            case 'xml':
                downloadXML({data: reduced, fileName: `events_export_${(new Date()).toISOString()}`});
                break;
            default:
                console.error('error on line ', type);
                break;
        }
    }
    const handleDownloadJSON = () => {
        eventsForDownload('json');
    };

    const handleDownloadCSV = () => {
        eventsForDownload('csv');
    };
    const handleDownloadXML = () => {
        eventsForDownload('xml');
    };


    useEffect(() => {
         const login = async () => {
            try {
                await AuthenticationService.login({
                    identifier: process.env.REACT_APP_API_IDENTIFIER ?? "",
                    secret: process.env.REACT_APP_API_SECRET ?? ""
                });
            } catch (error) {
                console.error('Error logging in:', error);
            }
        };

        login();
        fetchVenues();
        fetchTags();


    }, []);

    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}

            <EventsFilters setFiltersValue={applyFilters} venues={venuesList} tags={tagsList}/>

            <PaginationWithPageSizeSelector itemsPerPage={itemsPerPage} totalCount={totalCount}
                                            currentPage={currentPage} totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                            handlePageSizeChange={handlePageSizeChange}/>
            <div className="d-flex justify-content-between my-sm-3 my-3">
                <div>
                    <Button variant={viewMode === 'list' ? "primary": "light"} size="sm" onClick={()=>setViewMode('list')}><i className="bi bi-card-list"></i></Button>
                   <Button variant={viewMode === 'map' ? "primary": "light"} size="sm" className="mx-2" onClick={()=>setViewMode('map')}><i className="bi bi-map"></i></Button>
                </div>
                <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic-export" size={"sm"}>
                        {t('common.export')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={handleDownloadCSV}>
                            <FaFileCsv/> {t('common.exportTo', {value: "Csv"})}</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={handleDownloadJSON}>
                            <FaFileCode/> {t('common.exportTo', {value: "Json"})}</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={handleDownloadXML}>
                            <FaFileCode/> {t('common.exportTo', {value: "Xml"})} (beta)</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>


            <Tabs defaultActiveKey="ownedby_me" className="mt-3" id="setting-tabs" fill>
                <Tab eventKey="ownedby_me" title={
                    <>
                        {t('common.myEvents')} <Badge bg="primary">{(countEventsByOwned()).ownedByMe}</Badge>
                    </>
                } className="settings-tab-pannel">
                    <MyEvents events={myEvents} viewMode={viewMode}/>
                </Tab>
                <Tab eventKey="sharedwith_me" title={
                    <>
                        {t('common.shareWithMe')} <Badge bg="primary">{(countEventsByOwned()).shareWithMe}</Badge>
                    </>
                }
                     className="settings-tab-pannel">
                    <EventSharedWithMe events={sharedWithMeEvents} viewMode={viewMode}/>
                </Tab>
            </Tabs>
        </>
    );
}

export default EventsPage;
