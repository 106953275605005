import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import {useMap} from 'react-leaflet';
import {useEffect} from "react";
import './MapSearchField.scss'
// @ts-ignore
const SearchField = ({apiKey}) => {
    const provider = new OpenStreetMapProvider()
    // @ts-ignore
    const searchControl = new GeoSearchControl({
        provider: provider,
        style: "button",
        showMarker: false,
        updateMap: true,
        autoComplete: true,
        retainZoomLevel: false,
        animateZoom: true,
        keepResult: false,
        autoCompleteDelay: 250,
    });

    const map = useMap();
    // @ts-ignore
    useEffect(() => {
        map.addControl(searchControl);
        return () => map.removeControl(searchControl);
    }, []);

    return null;
};
export default SearchField;