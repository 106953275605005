import {Button, Col, Container, Row, Dropdown} from "react-bootstrap";
import {useRef, useState} from "react";
import RegistrationPersonalInformationView
    from "../../../views/RegistrationPersonalInformationView/RegistrationPersonalInformationView";
import LegalInformationRetrievalView from "../../../views/LegalInformationRetrievalView/LegalInformationRetrievalView";
// @ts-ignore
import logo from "../../../assets/logo-ticketr.svg";
// @ts-ignore
import mailSentBro from "../../../assets/mail-sent-bro.svg";
import {useTranslation} from "react-i18next";
import Step from "../../../components/Step/Step";
import CompanySelfRegistrationForm
    from "../../../components/companies/CompanySelfRegistrationForm/CompanySelfRegistrationForm";
import {Link} from "react-router-dom";

function RegistrationPage() {
    const [currentStep, setCurrentStep] = useState(1);
    const [registrationStepOneData, setRegistrationStepOneData] = useState(null);
    const [registrationStepTwoData, setRegistrationStepTwoData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const {t, i18n} = useTranslation();
    const registrationStepOneFormRef = useRef(null);
    const registrationStepTwoFormRef = useRef(null);

    const handleSubmit = async () => {
        setIsFormSubmitted(true);
    };
    const nextStep = async () => {
        switch (currentStep) {
            case 1:
                if (registrationStepOneFormRef.current) {
                    // @ts-ignore
                    const errors = await registrationStepOneFormRef.current?.validateForm();
                    // @ts-ignore
                    await registrationStepOneFormRef.current?.submitForm();
                    if (Object.keys(errors).length === 0) {
                        setCurrentStep(currentStep + 1);
                    }
                }
                break;
            case 2:
                if (registrationStepTwoFormRef.current) {
                    // @ts-ignore
                    const errors = await registrationStepTwoFormRef.current?.validateForm();

                    // @ts-ignore
                    await registrationStepTwoFormRef.current?.submitForm();
                    if (Object.keys(errors).length === 0) {
                        // @ts-ignore
                        if (registrationStepTwoData?.legalInformationRetrievingMethod as string == "MANUAL") {
                            setIsFormSubmitted(true);
                        }
                        setCurrentStep(currentStep + 1);
                    }
                }
                break;
            default:
                setCurrentStep(currentStep + 1);
        }
    }


    const prevStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleSelectLanguage = (lang: any) => {
        i18n.changeLanguage(lang);
    };


    return (
        <Container fluid={true} style={{height: "100vh"}}>
            <Row className="h-100">
                <Col xs={12} md={6} lg={6} sm={12} className="login-left"></Col>
                <Col xs={12} md={6} lg={6} sm={12} className="registration-right d-md-flex flex-column">
                    {/* Language Selector positioned in the top-right corner */}
                    <Dropdown className="position-fixed top-0 end-0 m-3">
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {i18n.language.toUpperCase()}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleSelectLanguage('en')}>English</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSelectLanguage('fr')}>Français</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>

                    <div className="w-100 align-items-center">

                        <Row className="mb-4 px-3 py-5">
                            <Col className="text-center" sm={12} md={12} lg={12}>
                                <img src={logo} alt="Logo" className="login-logo" height={60}/>
                                {!isFormSubmitted && (<div className="d-md-flex justify-content-between mt-5">
                                    <Step number={1} title={t('common.stepOne')} active={currentStep === 1}/>

                                    <Step number={2} title={t('common.stepTwo')} active={currentStep === 2}/>

                                    <Step number={3} title={t('common.stepThree')} active={currentStep === 3}/>
                                </div>)}

                            </Col>
                        </Row>
                        <Row className="px-3">
                            <Col>
                                {isFormSubmitted ? (
                                        <div className="text-center">
                                            <h3>{t('common.formSubmitted')}</h3>
                                            <p>{t('common.willBeContacted')}</p>
                                            <Link to="/auth/login"
                                                  className="btn btn-primary">{t('common.backToLogin')}</Link>
                                            <p>
                                                <img src={mailSentBro} alt="Mail sent " className="login-logo"
                                                     height={400}/>
                                            </p>

                                        </div>
                                    ) :
                                    (<>
                                        {currentStep === 1 &&
                                            <RegistrationPersonalInformationView ref={registrationStepOneFormRef}
                                                                                 initialValues={registrationStepOneData}
                                                                                 setRegistrationStepOneData={(values: any) => setRegistrationStepOneData(values)}/>}
                                        {currentStep === 2 &&
                                            <LegalInformationRetrievalView ref={registrationStepTwoFormRef}
                                                                           setParentIsLoading={(value: boolean) => setIsLoading(value)}
                                                                           setNextStep={() => setCurrentStep(currentStep + 1)}
                                                                           initialValues={registrationStepTwoData}
                                                                           setRegistrationStepTwoData={(values: any) => setRegistrationStepTwoData(values)}/>}
                                        {currentStep === 3 &&
                                            <CompanySelfRegistrationForm stepOneFormData={registrationStepOneData}
                                                                         stepTwoFormData={registrationStepTwoData}
                                                                         setIsFormSubmitted={() => setIsFormSubmitted(true)}/>}
                                    </>)}
                            </Col>
                        </Row>
                        {!isFormSubmitted && (
                            <Row className="mt-4">
                                <Col className="d-flex justify-content-between">
                                    <Button variant="secondary" onClick={prevStep} disabled={currentStep === 1}>
                                        {t('common.prev')}
                                    </Button>
                                    {currentStep < 3 &&
                                        <Button variant="primary" className="ml-2" onClick={nextStep}
                                                disabled={isLoading}>
                                            {t('common.next')}
                                        </Button>
                                    }

                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default RegistrationPage;
