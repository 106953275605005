import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {Formik} from "formik";
import {Col, Container, Form, Row} from "react-bootstrap";
import {useDebounce} from "use-debounce";

import React, {useEffect, useState} from "react";
import {SEPARATORS, WithContext as ReactTags} from "react-tag-input";

interface VenuesFiltersProps {
    setFiltersValue: (value: any) => void;

}

function VenuesFilters({setFiltersValue}: VenuesFiltersProps) {

    const {t} = useTranslation();

    const formInitialValues = {
        name: '',
        address: '',
        tags: [],
    };

    // State to store form values
    const [formValues, setFormValues] = useState(formInitialValues);
    const [debouncedValues] = useDebounce(formValues, 500);


    // Submit debounced values whenever they change
    useEffect(() => {
        setFiltersValue(debouncedValues);

    }, [debouncedValues]);

    // Function to handle form changes and update the local state
    const handleFormikChange = (newValues: any) => {
        setFormValues(newValues);
    };

    const searchFormSchema = yup.object().shape({
        name: yup.string().optional(),

    });

    const handleFilterFormSubmit = (values: any) => {
        setFiltersValue(values);
    }


    return (
        <div className="accordion" id="accordionEventFilter">
            <div className="accordion-item border-0">
                <h2 className="accordion-header mt-0">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {t('common.filters')}
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show"
                     data-bs-parent="#accordionEventFilter">
                    <div className="accordion-body">
                        <Formik
                            validationSchema={searchFormSchema}
                            onSubmit={handleFilterFormSubmit}
                            initialValues={formInitialValues}
                        >
                            {({handleSubmit, setFieldValue, values, touched, errors, resetForm}) => {
                                return (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Container>
                                            <Row className="gy-2">
                                                <Col lg={6} md={6} sm={6}>
                                                    <Form.Label>{t('common.name')}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        aria-label="name"
                                                        name="name"
                                                        placeholder={t('common.pleaseEnterName')}
                                                        value={values.name || ''}
                                                        onChange={(e) => {
                                                            setFieldValue('name', e.target.value)
                                                            handleFormikChange({...values, name: e.target.value});
                                                        }}
                                                        isInvalid={touched.name && !!errors.name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.name}
                                                    </Form.Control.Feedback>
                                                </Col>
                                                <Col lg={6} md={6} sm={12}>
                                                    <Form.Label>{t('common.address')}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        aria-label="address"
                                                        name="address"
                                                        placeholder={t('common.pleaseEnterAddress')}
                                                        value={values.address || ''}
                                                        onChange={(e) => {
                                                            setFieldValue('address', e.target.value)
                                                            handleFormikChange({...values, address: e.target.value});
                                                        }}
                                                        isInvalid={touched.address && !!errors.address}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.address}
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                            <Row className="gy-2">
                                                <Col lg={12} md={12} sm={12} className="tags">
                                                    <Form.Label>{t('common.tags')}</Form.Label>
                                                    <ReactTags
                                                        id="venueFilterTagsInput"
                                                        tags={values.tags}
                                                        separators={[SEPARATORS.COMMA, SEPARATORS.ENTER]}
                                                        handleDelete={(i) => {
                                                            const valFiltered = values.tags.filter((tag, index) => index !== i)
                                                            setFieldValue('tags', valFiltered)
                                                            handleFormikChange({...values, tags: valFiltered});
                                                        }
                                                        }
                                                        handleAddition={(tag) => {
                                                            setFieldValue('tags', [...values.tags, tag])
                                                            handleFormikChange({
                                                                ...values,
                                                                tags: [...values.tags, tag]
                                                            });
                                                        }}
                                                        inputFieldPosition="bottom"
                                                        autocomplete
                                                        allowDragDrop={false}
                                                        placeholder={t('common.pleaseEnterToAdd')}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12} md={12} sm={12}
                                                     className="d-md-flex flex-md-row justify-content-center py-3 text-center">
                                                    <button className="btn btn-primary btn-sm mt-2" type="submit"><i
                                                        className="bi bi-funnel"></i> {t('common.applyFilter')}
                                                    </button>
                                                    <button className="btn btn-info btn-sm mt-2 mx-md-2"
                                                            type="button" onClick={() => {
                                                        resetForm();
                                                        setFiltersValue({})
                                                    }}>
                                                        <i className="bi bi-trash"></i>{t('common.resetFilter')}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VenuesFilters;