import './Header.css'
// @ts-ignore
import logo from '../../assets/logo-ticketr.svg'
// @ts-ignore
import favicoTicketr from '../../assets/favicon_ticketr.svg';
import {useTranslation} from "react-i18next";
import routes from "../../router/routes";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import AuthenticationService from "../../services/AuthenticationService";
import {User} from "oidc-client-ts";

type LocaleType = 'en' | 'fr';

const locales: Record<LocaleType, { title: string }> = {
    en: {title: 'English'},
    fr: {title: 'Français'}
};

interface HeaderProps {
    isSideBarVisible?: boolean;
    setIsSideBarVisible: () => void;
}

function Header({isSideBarVisible, setIsSideBarVisible}: HeaderProps) {
    const {t, i18n} = useTranslation();
    const location = useLocation();
    const currentRoute = routes.find((item: any) => item.path === location.pathname);
    const [currentUser, setCurrentUser] = useState<User | null>(null)
    useEffect(() => {
        AuthenticationService.getLoggedUserInfo().then(value => {
            setCurrentUser(value);
        })
    }, [])

    return (
        <header id="header"
                className={`header fixed-top d-flex align-items-center${isSideBarVisible ? '' : ' collapsed'}`}>
            <div className="d-flex align-items-center justify-content-between">
                <a href="/" className="d-flex align-items-center">
                    {isSideBarVisible ? (<img src={logo} alt="Ticketr Logo" className="logo-ticketr"/>) :
                        (<img src={favicoTicketr} alt="Ticketr Logo" className="logo-favi-img"/>)
                    }
                    {/*<span className="logo-text">t</span>*/}
                </a>
                <i className="bi bi-list toggle-sidebar-btn" onClick={() => setIsSideBarVisible()}></i>
            </div>

            <div className="px-3 py-2">
                <h4>{t(`${currentRoute?.label}`)}</h4>
            </div>
            {/*<div className="search-bar">
                <form className="search-form d-flex align-items-center" method="POST" action="#">
                    <input type="text" name="query" placeholder="Search" title="Enter search keyword"/>
                    <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                </form>
            </div>*/}
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    {/*<li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li>


                    <li className="nav-item dropdown">

                        <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                            <i className="bi bi-bell"></i>
                            <span className="badge bg-primary badge-number">4</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                            <li className="dropdown-header">
                                {t('header.newNotificationCount', {count: 5})}
                                <a href="#"><span
                                    className="badge rounded-pill bg-primary p-2 ms-2">{t('header.viewAll')}</span></a>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li className="notification-item">
                                <i className="bi bi-exclamation-circle text-warning"></i>
                                <div>
                                    <h4>Lorem Ipsum</h4>
                                    <p>Quae dolorem earum veritatis oditseno</p>
                                    <p>30 min. ago</p>
                                </div>
                            </li>

                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li className="notification-item">
                                <i className="bi bi-x-circle text-danger"></i>
                                <div>
                                    <h4>Atque rerum nesciunt</h4>
                                    <p>Quae dolorem earum veritatis oditseno</p>
                                    <p>1 hr. ago</p>
                                </div>
                            </li>

                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li className="notification-item">
                                <i className="bi bi-check-circle text-success"></i>
                                <div>
                                    <h4>Sit rerum fuga</h4>
                                    <p>Quae dolorem earum veritatis oditseno</p>
                                    <p>2 hrs. ago</p>
                                </div>
                            </li>

                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li className="notification-item">
                                <i className="bi bi-info-circle text-primary"></i>
                                <div>
                                    <h4>Dicta reprehenderit</h4>
                                    <p>Quae dolorem earum veritatis oditseno</p>
                                    <p>4 hrs. ago</p>
                                </div>
                            </li>

                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <li className="dropdown-footer">
                                <a href="#">Show all notifications</a>
                            </li>

                        </ul>


                    </li>*/}


                    {/* <li className="nav-item dropdown">

                        <a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">
                            <i className="bi bi-chat-left-text"></i>
                            <span className="badge bg-success badge-number">3</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                            <li className="dropdown-header">
                                {t('header.newMessageCount', {count: 2})}
                                <a href="#"><span
                                    className="badge rounded-pill bg-primary p-2 ms-2">{t('header.viewAll')}</span></a>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li className="message-item">
                                <a href="#">
                                    <img src="https://i.pravatar.cc/150?img=6" alt="" className="rounded-circle"/>
                                    <div>
                                        <h4>Polo Polo</h4>
                                        <p>Velit asperiores et ducimus soluta repudiandae labore officia est
                                            ut...</p>
                                        <p>4 hrs. ago</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li className="message-item">
                                <a href="#">
                                    <img src="assets/img/messages-2.jpg" alt="" className="rounded-circle"/>
                                    <div>
                                        <h4>Anna Nelson</h4>
                                        <p>Velit asperiores et ducimus soluta repudiandae labore officia est
                                            ut...</p>
                                        <p>6 hrs. ago</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li className="message-item">
                                <a href="#">
                                    <img src="assets/img/messages-3.jpg" alt="" className="rounded-circle"/>
                                    <div>
                                        <h4>David Muldon</h4>
                                        <p>Velit asperiores et ducimus soluta repudiandae labore officia est
                                            ut...</p>
                                        <p>8 hrs. ago</p>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li className="dropdown-footer">
                                <a href="#">Show all messages</a>
                            </li>

                        </ul>


                    </li>*/}

                    {/*<li className="nav-item ">
                        <a className="nav-link nav-icon" href="/account">
                            <i className="bi bi-gear"></i>
                        </a>
                    </li>*/}
                    <li className="nav-item dropdown pe-3">

                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#"
                           data-bs-toggle="dropdown">
                            <img src="https://i.pravatar.cc/150?img=6" alt="Profile" className="rounded-circle"/>
                            <span className="d-none d-md-block dropdown-toggle ps-2">{currentUser?.profile.name}</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{currentUser?.profile?.name}</h6>
                                <span>{currentUser?.profile?.email}</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="#"
                                   onClick={(e) => e.preventDefault()}>
                                    <i className="bi bi-person"></i>
                                    <span>{t('header.myProfile')}</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            {/*
                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="/account">
                                    <i className="bi bi-gear"></i>
                                    <span>{t('header.accountSettings')}</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>

                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="#"
                                   onClick={(e) => e.preventDefault()}>
                                    <i className="bi bi-question-circle"></i>
                                    <span>{t('header.needHelp')}</span>
                                </a>
                            </li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            */}

                            <li>
                                <a className="dropdown-item d-flex align-items-center" href="#"
                                   onClick={AuthenticationService.logout}>
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>{t('header.signOut')}</span>
                                </a>
                            </li>

                        </ul>
                    </li>
                    <li className="nav-item dropdown pe-3">
                        <a className="nav-link nav-swich-langage d-flex align-items-center pe-0" href="#"
                           data-bs-toggle="dropdown">
                            <span className="d-none d-md-block dropdown-toggle ps-2">{i18n.resolvedLanguage?.toUpperCase()}</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow swich-langage">
                            {Object.keys(locales).map((locale) => (
                                <li key={locale}>
                                    <a className="dropdown-item d-flex align-items-center" href="#"
                                       onClick={() => i18n.changeLanguage(locale as LocaleType)}>
                                        <span style={{fontWeight: i18n.resolvedLanguage === locale ? 'bold' : 'normal'}}

                                        >

                                        {locales[locale as LocaleType].title}
                                            </span>
                                    </a>
                                </li>
                            ))}


                        </ul>
                    </li>

                </ul>
            </nav>
        </header>
    )
}

export default Header;