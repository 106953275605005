/*import React from 'react';

const DownloadCSV = (payload: { data: any, fileName: string }) => {
    const convertToCSV = (objArray: any) => {
  // Data entry format verification
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

  // Unique keys extract from all Objects
  const keys = Array.from(new Set(array.flatMap((item: any) => Object.keys(item))));

  let csvContent = '';

  // Créer la ligne d'en-têtes (les noms des colonnes), avec ";" comme séparateur
  csvContent += keys.join(';') + '\r\n';

  // Ajouter les données des objets dans les colonnes correspondantes
  for (let i = 0; i < array.length; i++) {
    let line = '';

    for (let key of keys) {
      if (line !== '') line += ';'; // Utiliser le point-virgule comme séparateur

      // Ajouter la valeur si elle existe, sinon laisser vide
      let value = array[i][key as any] !== undefined ? array[i][key as any] : '';

      // Si la valeur est un tableau, traiter le contenu
      if (Array.isArray(value)) {
        value = value
          .map((item: any) => {
            if (typeof item === 'object') {
              // Si c'est un objet, extraire ses valeurs et les séparer par '|'
              return JSON.stringify(item);
            } else {
              // Si c'est une chaîne, laisser tel quel
              return item;
            }
          })
          .join('|'); // Séparer chaque élément du tableau par '|'
      }

      // Protéger les chaînes contenant des caractères spéciaux (point-virgule, guillemets, ou retour à la ligne)
      if (typeof value === 'string') {
        if (value.includes(';') || value.includes(',') || value.includes('"') || value.includes('\n')) {
          // Échapper les guillemets en les doublant
          value = `"${value.replace(/"/g, '""')}"`;
        }
      }

      line += value;
    }

    csvContent += line + '\r\n';
  }

  return csvContent;
};

    const downloadCSV = () => {
        const csvData = new Blob([convertToCSV(payload.data)], {type: 'text/csv'});
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${payload.fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <button onClick={downloadCSV}>Download CSV</button>
    );
}

export default DownloadCSV;*/
const convertToCSV = (objArray: any): string => {
    // Data entry format verification
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    // Unique keys extract from all Objects
    const keys = Array.from(new Set(array.flatMap((item: any) => Object.keys(item))));

    let csvContent = '';

    // Créer la ligne d'en-têtes (les noms des colonnes), avec ";" comme séparateur
    csvContent += keys.join(';') + '\r\n';

    // Ajouter les données des objets dans les colonnes correspondantes
    for (let i = 0; i < array.length; i++) {
        let line = '';

        for (let key of keys) {
            if (line !== '') line += ';'; // Utiliser le point-virgule comme séparateur

            // Ajouter la valeur si elle existe, sinon laisser vide
            let value = array[i][key as any] !== undefined ? array[i][key as any] : '';

            // Si la valeur est un tableau, traiter le contenu
            if (Array.isArray(value)) {
                value = value
                    .map((item: any) => {
                        if (typeof item === 'object') {
                            // Si c'est un objet, extraire ses valeurs et les séparer par '|'
                            return JSON.stringify(item);
                        } else {
                            // Si c'est une chaîne, laisser tel quel
                            return item;
                        }
                    })
                    .join('|'); // Séparer chaque élément du tableau par '|'
            }

            if(typeof value === 'object') {
                value = JSON.stringify(value);
            }

            // Protéger les chaînes contenant des caractères spéciaux (point-virgule, guillemets, ou retour à la ligne)
            if (typeof value === 'string') {
                if (value.includes(';') || value.includes(',') || value.includes('"') || value.includes('\n')) {
                    // Échapper les guillemets en les doublant
                    value = `"${value.replace(/"/g, '""')}"`;
                }
            }

            line += value;
        }

        csvContent += line + '\r\n';
    }

    return csvContent;
};

const downloadCSV = (payload: { data: any, fileName: string }): void => {
    const csvData = new Blob([convertToCSV(payload.data)], {type: 'text/csv'});
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${payload.fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export default downloadCSV;