import * as yup from 'yup';
import i18next from 'i18next'; // Assurez-vous d'importer i18n si nécessaire

import ComplianceService from "../services/ComplianceService";  // Assurez-vous d'importer la fonction checkSiret depuis l'endroit correct

const checkSiret = async (siret: string) => {
    try {
        const response = await ComplianceService.verifySiretNumber(siret);

        return true;
    } catch (e: any) {
        console.error(e);
        if (e.response.status === 401) {
            alert("show toast and discuss with guys");
        }
        return false;
    }
}
export const createSiretValidation = () => {
    return yup.string()
        .min(14, i18next.t('common.inputSiretLength', {value: process.env.siretMaxNumber || 14}))
        .max(14, i18next.t('common.inputSiretLength', {value: process.env.siretMaxNumber || 14}))
        .required(i18next.t('common.inputRequired'))
        .test('is-valid-siret-number', i18next.t('common.invalidSiretNumber'), async (value) => {
            console.log("validation is trigerred");
            if (value && value.length === 14) {
                return await checkSiret(value);
            }
            return false;
        });
};
