import {Form, Col, Button, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import React, {useState} from "react";
import {Formik} from "formik";

function ProfileForm() {
    const {t, i18n} = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const formInitialValues = {
        firstname: null,
        lastname: null,
        email: null,
        position: null,
    };
    const profileFormSchema = yup.object().shape({
        firstname: yup.string().required(i18n.t('common.inputRequired')),
        lastname: yup.string().required(i18n.t('common.inputRequired')),
        email: yup.string().email(i18n.t('common.invalidEmail')).required(i18n.t('common.inputRequired')),
        position: yup.string().required(i18n.t('common.inputRequired'))
    })
    const handleFormSubmit = (values: any) => {
        setIsLoading(true)
        alert('Form Submitted endpoint will called once it was improved');
        setIsLoading(false);
    }
    return (
        <Formik
            validationSchema={profileFormSchema}
            onSubmit={handleFormSubmit}
            initialValues={formInitialValues}
        >
            {({handleSubmit, setFieldValue, values, touched, errors}) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <Form.Group as={Col} controlId="profilFormFirsname" className="mb-3">
                                <Form.Label>{t('common.firstname')}</Form.Label>
                                <Form.Control type="text" placeholder={t('common.firstNamePlaceHolder')}
                                              aria-label={t('common.firstname')} name="firstname"
                                              value={values.firstname || ''}
                                              onChange={(e) => setFieldValue('firstname', e.target.value)}
                                              isInvalid={touched.firstname && !!errors.firstname}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors.firstname}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>

                            <Form.Group as={Col} controlId="profilFormName" className="mb-3">
                                <Form.Label>{t('common.name')}</Form.Label>
                                <Form.Control type="text" placeholder={t('common.namePlaceHolder')}
                                              aria-label={t('common.name')} name="lastname"
                                              value={values.lastname || ''}
                                              onChange={(e) => setFieldValue('lastname', e.target.value)}
                                              isInvalid={touched.lastname && !!errors.lastname}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors.lastname}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                    </Row>

                    <Form.Group as={Col} controlId="profilFormPoste" className="mb-3">
                        <Form.Label>{t('common.position')}</Form.Label>
                        <Form.Control type="text" placeholder={t('common.pleaseEnterYourPosition')}
                                      aria-label={t('common.position')} name="position"
                                      value={values.position || ''}
                                      onChange={(e) => setFieldValue('position', e.target.value)}
                                      isInvalid={touched.position && !!errors.position}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.position}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="profileFormEmail">
                        <Form.Label>{t('common.email')}</Form.Label>
                        <Form.Control type="email" placeholder={t('common.emailPlaceholder')}
                                      aria-label={t('common.email')}
                                      value={values.email || ''}
                                      onChange={(e) => setFieldValue('email', e.target.value)}
                                      isInvalid={touched.email && !!errors.email}/>
                        <Form.Control.Feedback type="invalid">
                            {errors.email}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" id="formGridCheckbox">
                        <Button variant="primary" type="submit">{t('common.save')}</Button>
                    </Form.Group>

                </Form>)}
        </Formik>
    )
}

export default ProfileForm;