
const downloadJSON = (payload:{data: any, fileName: string}) => {
  const jsonData = new Blob([JSON.stringify(payload.data)], { type: 'application/json' });
  const jsonURL = URL.createObjectURL(jsonData);
  const link = document.createElement('a');
  link.href = jsonURL;
  link.download = `${payload.fileName}.json`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export default downloadJSON;
