// MainLayout.tsx
import React, { useEffect, useState } from 'react';
import Header from "./header/Header";
import SideBar from "./side-bar/SideBar";
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
    const [isSideBarVisible, setIsSideBarVisible] = useState(true);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1199) {
                setIsSideBarVisible(true);
            } else {
                setIsSideBarVisible(false);
            }
        };

         const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset > 100);
        };

        // Initial check
        handleResize();



        // Add event listener
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    return (
        <>
            <Header
                isSideBarVisible={isSideBarVisible}
                setIsSideBarVisible={() => setIsSideBarVisible(!isSideBarVisible)}
            />

            <SideBar isSideBarVisible={isSideBarVisible} />
            <main className={`main-content ${isSideBarVisible ? 'opened' : 'collapsed'}`} id="main">
                {/*<div className={`sticky-trial ${isSticky ? 'reduced' : ''}`}>
                    <TrialVersionComponent/>
                </div>*/}
                <Outlet/> {/* Renders nested routes here */}
            </main>
        </>
    );
};

export default MainLayout;
