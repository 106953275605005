import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {Col, Container, Form,  Row} from "react-bootstrap";
import {Formik} from "formik";
import React, {ForwardedRef, forwardRef, Fragment, useImperativeHandle, useRef} from "react";
import {ACCOUNT_TYPE_LIST} from "../../utils/constants";

const accountTypeOptions = ACCOUNT_TYPE_LIST;
const renderAccountTypeOptions = (option: string) => {
    return <option value={option}>{option}</option>;
};

interface FormHandles {
    validateForm: () => Promise<any>;
    submitForm: () => Promise<any>;
}
interface RegistrationPersonalInformationViewProps {
    setRegistrationStepOneData: (value: any) => void,
    initialValues:{ email: string,
        name: string,
        businessName: string,
        personContact: string,
        accountType: string
    } | null
}


const RegistrationPersonalInformationView = forwardRef<FormHandles,RegistrationPersonalInformationViewProps>(({setRegistrationStepOneData,initialValues}:RegistrationPersonalInformationViewProps, ref: ForwardedRef<FormHandles>) => {
    const {t} = useTranslation();
    const formikRef = useRef<any>(null);

    const formInitialValues = {
        email: initialValues?.email ,
        name: initialValues?.name,
        businessName: initialValues?.businessName ,
        personContact: initialValues?.personContact ,
        accountType: initialValues?.accountType ,
    };
    const validationSchema = yup.object().shape({
        name: yup.string().required(t('common.inputRequired')),
        email: yup.string().email(t('common.invalidEmail')).required(t('common.inputRequired')),
        businessName: yup.string().required(t('common.inputRequired')),
        personContact: yup.string().required(t('common.inputRequired')),
        accountType: yup.string().required(t('common.inputRequired'))
    });

    const handleFormSubmit = (values: any) => {
        setRegistrationStepOneData(values)

    };


    // Expose methods to parent via ref
    useImperativeHandle(ref, () => {
        return {
            validateForm: () => {
                console.log('Submitted values:', formikRef);
                return formikRef.current?.validateForm() ?? Promise.reject();
            },
            submitForm: () => {
                return formikRef.current?.submitForm() ?? Promise.reject();
            }
        }

    }, []);

    return (
        <Formik
            innerRef={formikRef}
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({values, setFieldValue, handleSubmit, errors, touched}) => (
                <>
                    <Form noValidate onSubmit={handleSubmit}>
                        <Container>
                            <Row>
                                <Col lg={12} md={12} sm={12}>
                                    <Form.Group className="mb-3" controlId="companyInitializerFormNameInput">
                                        <Form.Label>{t('common.name')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-label={t('common.name')}
                                            name="name"
                                            placeholder={t('common.pleaseEnterCompanyName')}
                                            value={values.name || ''}
                                            onChange={(e) => setFieldValue('name', e.target.value)}
                                            isInvalid={touched.name && !!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3"
                                                controlId="companyInitializerFormBusinessNameInput">
                                        <Form.Label>{t('common.businessName')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-label={t('common.businessName')}
                                            name="businessName"
                                            placeholder={t('common.pleaseEnterCompanyBusinessName')}
                                            value={values.businessName || ''}
                                            onChange={(e) => setFieldValue('businessName', e.target.value)}
                                            isInvalid={touched.businessName && !!errors.businessName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.businessName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3"
                                                controlId="companyInitializerFormPersonContactInput">
                                        <Form.Label>{t('common.personContact')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-label={t('common.personContact')}
                                            name="personContact"
                                            placeholder={t('common.pleaseEnterCompanyPersonContact')}
                                            value={values.personContact || ''}
                                            onChange={(e) => setFieldValue('personContact', e.target.value)}
                                            isInvalid={touched.personContact && !!errors.personContact}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.personContact}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="companyInitializerFormEmailInput">
                                        <Form.Label>{t('common.email')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            aria-label={t('common.email')}
                                            name="email"
                                            placeholder={t('common.pleaseEnterCompanyEmail')}
                                            value={values.email || ''}
                                            onChange={(e) => setFieldValue('email', e.target.value)}
                                            isInvalid={touched.email && !!errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="companyInitializerFormAccountTypeInput">
                                        <Form.Label>{t('common.accountType')}</Form.Label>
                                        <Form.Select aria-label={t('common.accountType')}
                                                     value={values.accountType || ''}
                                                     name="accountType"
                                                     onChange={(e) => {
                                                         setFieldValue('accountType', e.target.value);
                                                     }}
                                                     isInvalid={touched?.accountType && !!errors?.accountType}>
                                            <option>{t('common.pleaseSelect')}.</option>
                                            {accountTypeOptions.map((opt) => (
                                                <Fragment key={opt}>{renderAccountTypeOptions(opt)}</Fragment>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.accountType}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Container>
                    </Form>

                </>
            )}
        </Formik>

    )
})

export default RegistrationPersonalInformationView;