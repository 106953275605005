import {Button, Modal, Spinner, Table, Alert} from "react-bootstrap";
import {MultiValue} from 'react-select'

import {useState} from "react";
import {useTranslation} from "react-i18next";
import AsyncSelect from "react-select/async";
import {CompanyInterface} from "../../@interfaces/CompanyInterface";
import CompaniesService from "../../services/CompaniesService";
import EventsService from "../../services/EventsService";
import {EventInterface} from "../../@interfaces/EventInterface";
import {hide} from "@popperjs/core";
import {handleError} from "../../utils/ResponseWrapper";
import showToast from "../../utils/showToast";

const options = [
    {value: 'Compagnie 1', label: 'Compagnie 1'},
    {value: 'Compagnie 2', label: 'Compagnie 2'},
    {value: 'Compagnie N', label: 'Compaie N'}
]

interface CompaniesResponse {
    data: CompanyInterface[];
    headers: any;
}

interface shareEventModalProps {
    show: boolean,
    onHide: () => void,
    eventsToBeShare: any,
}

function ShareEventModal({show, onHide, eventsToBeShare}: shareEventModalProps) {
    const {t, i18n} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [selectedCompanies, setSelectedCompanies] = useState<MultiValue<{
        value: string;
        label: string;
    }> | null>([]);

    const loadOptions = (inputValue: string) =>
        new Promise<{ value: string, label: string }[]>((resolve) => {
            setTimeout(() => {
                CompaniesService.retrieveCompanies({
                    pagination: {
                        currentPage: 1,
                        pageSize: 0,
                    },
                    filters: {names: [{text: inputValue}]}
                }).then((response: CompaniesResponse) => {

                    const formattedOptions = response.data.map(company => ({
                        value: company.id as string,
                        label: company.name as string
                    }));
                    resolve(formattedOptions);
                }, error => {
                    console.error(error);
                });
            }, 1000);
        });

    const shareEvents = async () => {
        try {
            setIsLoading(true)
            if (selectedCompanies) {
                const payload = {
                    target_ids: eventsToBeShare.map((event: EventInterface) =>
                        event.id
                    ),
                    recipient_id: selectedCompanies[0].value,
                    target_type:"event"
                }
              await EventsService.shareEvent(payload);
            }
            hideModal();
            setIsLoading(false);
            showToast(t('ShareEventModal.eventsSuccessfullyShared'),'success');

        } catch (e: any) {
            const error = handleError(e);
            setErrorMessage(error.data);
            setIsLoading(false);
            showToast(t('common.errorWhileShareEvent'),'error')
        }
    }

    const hideModal = ()=>{
        setIsLoading(false);
        setErrorMessage(null);
        setSelectedCompanies([]);
        onHide();
    }


    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('ShareEventModal.title')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorMessage && <Alert variant={"danger"}>
                    {errorMessage}
                </Alert>}

                <h5>{t('ShareEventModal.pleaseSelectCompany')} </h5>
                <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    onChange={setSelectedCompanies}
                    loadOptions={loadOptions}
                    className="mb-3 mt-3"
                />

                {selectedCompanies && selectedCompanies.length > 0 && (
                    <>
                        <p>{t('ShareEventModal.description', {count: eventsToBeShare.length})}</p>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>{t('common.name')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedCompanies.map((value, index) => (
                                <tr key={index}>
                                    <td>{value.label}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </>
                )}


            </Modal.Body>
            <Modal.Footer>
                {isLoading ? (
                    <Button onClick={shareEvents} className="rounded" disabled={isLoading}> <Spinner animation="border"
                                                                                                     variant="light"
                                                                                                     size={"sm"}/> {t('common.loading')}
                    </Button>) : (
                    <Button onClick={shareEvents} className="rounded"> {t('common.share')}</Button>
                )}
                <Button onClick={hideModal} className="rounded" variant="danger">{t('common.canceled')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ShareEventModal;