import {Button, Modal} from "react-bootstrap";
//import "./AlertModal.scss"
import {useTranslation} from "react-i18next";
import EventDetailsView from "../../views/EventDetailsView/EventDetailsView";
import {EventInterface} from "../../@interfaces/EventInterface";

interface EventDetailsModalProps {
    event: EventInterface,
    onHide: ()=>void,
    show:boolean
}
function EventDetailsModal({event,onHide,show}: EventDetailsModalProps) {
     const {t, i18n} = useTranslation();
    return (
        <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            animation={true}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('common.eventDetails')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EventDetailsView event={event} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} className="rounded">{t('common.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default EventDetailsModal;