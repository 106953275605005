const convertToXML = (objArray: any): string => {
  const processObject = (obj: any): string => {
    let xml = '';

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let value = obj[key];

        // Si la valeur est un tableau
        if (Array.isArray(value)) {
          // Séparer les valeurs d'un tableau d'objets ou de chaînes par '|'
          value = value
            .map((item: any) => {
              if (typeof item === 'object') {
                //xml += `<${key}>${processObject(value)}</${key}>`
                return JSON.stringify(item); // Concaténer les valeurs de l'objet par '|'
              } else {
                return item; // Laisser la chaîne telle quelle
              }
            })
            .join('|'); // Séparer les éléments du tableau par '|'
        }

        // Si la valeur est un objet, le traiter récursivement
        if (typeof value === 'object' && value !== null) {
          xml += `<${key}>${processObject(value)}</${key}>`;
        } else {
          // Si la valeur est une chaîne ou autre, protéger les caractères spéciaux
          value = `${value}`
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&apos;');
          xml += `<${key}>${value}</${key}>`;
        }
      }
    }
    return xml;
  };

  // Traitement principal pour un tableau d'objets
  let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n<root>';

  objArray.forEach((item: any) => {
    xmlContent += '<item>';
    xmlContent += processObject(item);
    xmlContent += '</item>';
  });

  xmlContent += '</root>';
  return xmlContent;
};

// Fonction pour déclencher le téléchargement du fichier XML
const downloadXML = (payload: { data: any, fileName: string }): void => {
  const xmlData = new Blob([convertToXML(payload.data)], { type: 'application/xml' });
  const xmlURL = URL.createObjectURL(xmlData);
  const link = document.createElement('a');
  link.href = xmlURL;
  link.download = `${payload.fileName}.xml`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadXML;

/*const convertToXML = (objArray: any): string => {
  // Fonction pour traiter les objets et générer du XML
  const processObject = (obj: any, indent: string = ''): string => {
    let xml = '';
    const currentIndent = indent + '  '; // Ajouter deux espaces pour l'indentation des niveaux suivants

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        let value = obj[key];

        // Si la valeur est un tableau
        if (Array.isArray(value)) {
          value = value
            .map((item: any) => {
              if (typeof item === 'object') {
                return processObject(item, currentIndent); // Traiter les objets dans le tableau
              } else {
                // Protéger les chaînes de caractères
                return `${item}`
                  .replace(/&/g, '&amp;')
                  .replace(/</g, '&lt;')
                  .replace(/>/g, '&gt;')
                  .replace(/"/g, '&quot;')
                  .replace(/'/g, '&apos;');
              }
            })
            .join('|'); // Séparer les éléments du tableau par '|'
        }

        // Si la valeur est un objet, le traiter récursivement
        if (typeof value === 'object' && value !== null) {
          xml += `\n${indent}<${key}>${processObject(value, currentIndent)}</${key}>`;
        } else {
          // Protéger les caractères spéciaux dans la valeur
          value = `${value}`
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&apos;');
          xml += `\n${indent}<${key}>${value}</${key}>`;
        }
      }
    }
    return xml;
  };

  // Traitement principal pour un tableau d'objets
  let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n<root>';

  objArray.forEach((item: any) => {
    xmlContent += `\n  <item>${processObject(item, '  ')}</item>`;
  });

  xmlContent += '\n</root>';
  return xmlContent;
};

// Fonction pour déclencher le téléchargement du fichier XML
const downloadXML = (payload: { data: any, fileName: string }): void => {
  const xmlData = new Blob([convertToXML(payload.data)], { type: 'application/xml' });
  const xmlURL = URL.createObjectURL(xmlData);
  const link = document.createElement('a');
  link.href = xmlURL;
  link.download = `${payload.fileName}.xml`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadXML;*/
