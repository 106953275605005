import axiosClient from "./AxiosInstance";

import {createZitadelAuth, ZitadelAuth, ZitadelConfig} from "@zitadel/react";
import {User, UserManager, UserManagerSettings} from 'oidc-client-ts';


const config: ZitadelConfig = {
    authority: process.env.REACT_APP_ZITADEL_AUTHORITY,
    client_id: process.env.REACT_APP_ZITADEL_CLIENT_ID,
    //scope:"openid%20email%20profile%20urn%3Azitadel%3Aiam%3Aorg%3Aid%3A283308643338682679",
    //scope:"urn:zitadel:iam:org:id:283308643338682679",
    redirect_uri: process.env.REACT_APP_ZITADEL_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_ZITADEL_POST_LOGOUT_REDIRECT_URI
};

const zitadelConfig = createZitadelAuth(config,);

export default class AuthenticationService {

    private static zitadelConfig: ZitadelAuth;

    static configureZitadel(config: ZitadelConfig) {
        AuthenticationService.zitadelConfig = createZitadelAuth(config);
    }

    static async login(credential: { identifier: string, secret: string }) {
        try {
            const response = await axiosClient.post("/auth", credential);
            localStorage.setItem("token", response.data.jwt);
        } catch (error) {
            throw error;
        }
    }

    static async tokenIntrospection(token: string) {
        try {
            const response = await axiosClient.post("/auth/introspect", {token: token});
            console.log(response.data);
            //Todo token datas upating
            //localStorage.setItem("loggedUser",JSON.stringify());

        } catch (error) {
            throw error;
        }
    }

    static async loginZitadel() {
        try {
            await zitadelConfig.authorize();
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    }

    static async isAuthenticated(): Promise<boolean> {
        try {
            if (AuthenticationService.zitadelConfig) {
                const user = await AuthenticationService.zitadelConfig.userManager.getUser();
                return !!user;
            }
            return false;
        } catch (error) {
            throw error;
        }
    }

    static async handleSigninRedirectCallback(): Promise<User | null> {
        try {
            return await AuthenticationService.zitadelConfig.userManager.signinRedirectCallback();

        } catch (error) {
            //console.error("Error handling signin redirect callback:", error);
            throw error;
        }
    }

    static async getLoggedUserInfo(): Promise<User | null> {
        try {
            return await AuthenticationService.zitadelConfig.userManager.getUser();
        } catch (error) {
            throw error;
        }
    }

    static async logout() {
        try {
            if (AuthenticationService.zitadelConfig) {
                await AuthenticationService.zitadelConfig.signout();
            }
            localStorage.removeItem("token");
        } catch (error) {
            throw error;
        }
    }


}
AuthenticationService.configureZitadel(config);