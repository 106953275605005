import {Link, Navigate} from "react-router-dom";
import {useEffect, useState} from "react";
import AuthenticationService from "../../../services/AuthenticationService";
import './LoginPage.scss'; // Assurez-vous d'ajouter ce fichier CSS dans le même répertoire
// @ts-ignore
import logo from '../../../assets/logo-ticketr.svg'
import {Button, Col, Container, Dropdown, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function LoginPage(): JSX.Element {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);
    const {t, i18n} = useTranslation();
    const handleSelectLanguage = (lang: any) => {
        i18n.changeLanguage(lang);
    };


    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const isAuth = await AuthenticationService.isAuthenticated();
                setAuthenticated(isAuth);
            } catch (error) {
                console.error("Error checking authentication:", error);
                setAuthenticated(false);
            }
        };

        checkAuthentication();
    }, []);

    const handleLogin = async () => {
        try {
            await AuthenticationService.loginZitadel();
        } catch (error) {
            console.error("Login failed:", error);
        }
    };

    if (authenticated) {
        return <Navigate to="/events" replace={true}/>;
    }

    return (
       <Container fluid={true} style={{height: "100vh"}}>
            <Row className="h-100">
                <Col xs={12} md={6} lg={6} sm={12} className="login-left"></Col>
                <Col xs={12} md={6} lg={6} sm={12} className="registration-right d-md-flex flex align-items-center">
                    <Dropdown className="position-fixed top-0 end-0 m-3">
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                            {i18n.language.toUpperCase()}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleSelectLanguage('en')}>English</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSelectLanguage('fr')}>Français</Dropdown.Item>
                            {/* Add more languages here */}
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="w-100 text-center">
                        <img src={logo} alt="Logo" className="login-logo mb-5" height={100}/>
                        <h4>{t('common.wellcomeText')}</h4>

                        <div className="mt-3 text-center">
                            <p><i>{t('common.notHaveAccount')}</i></p>

                            <div className="mt-3">
                                <a href="#" onClick={handleLogin} className="mx-2">{t('common.testForFree')}</a>
                                <Link to="/auth/registration">{t('common.registerAsPro')}</Link>
                            </div>
                        </div>
                        <Button onClick={handleLogin} className="mt-3" variant="primary" size="sm">
                            {t('common.wellcomeLoginButtonText')}
                        </Button>


                    </div>
                </Col>
            </Row>
       </Container>
    );
}

export default LoginPage;
