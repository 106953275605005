import './Step.scss'
interface StepProps {
    title?: string;
    number?: number;
    active?: boolean;

}
function Step({ title, number, active }: StepProps) {
    return (
        <div className={`stepper-step ${active ? 'active' : ''}`}>
            <div className="stepper-circle">{number}</div>
            <div className="stepper-title">{title}</div>
        </div>
    )
}

export default Step;