import {LegalStatus} from "./legalStatusEnum";

export const ATTACHMENT_TYPE_BY_LEGAL_STATUS = [
    {label: LegalStatus.SAS, value: ['KBIS']},
    {label: LegalStatus.SARL, value: ['KBIS']},
    {label: LegalStatus.SASU, value: ['KBIS']},
    {label: LegalStatus.EURL, value: ['KBIS']},
    {label: LegalStatus.SCP, value: ['KBIS']},
    {label: LegalStatus.ONG, value: ['JOAFE (RÉCIPISSÉ RNA)']},
    {label: LegalStatus.ASSOC, value: ['JOAFE (RÉCIPISSÉ RNA)']},
]

export const COMPANY_LEGAL_STATUS_LIST = [LegalStatus.SAS, LegalStatus.SARL, LegalStatus.SASU, LegalStatus.SCP, LegalStatus.EURL, LegalStatus.ONG, LegalStatus.ASSOC];
export const BUSINESS_SECTORS = [
    {label: "Culture", value: "Culture"},
    {label: "Evénementiel", value: "Evénementiel"},
    {label: "Visite / Musées", value: "Visite / Musées"},
    {label: "Foire & Salon", value: "Foire & Salon"},
    {label: "Parcs & Loisirs", value: "Parcs & Loisirs"},
    {label: "Sport", value: "Sport"},
    {label: "Tourisme", value: "Tourisme"},
    {label: "Agence de voyage", value: "Agence de voyage"},
    {label: "Application métier", value: "Evénementiel"},
    {label: "Collectivité / Institutionnel", value: "Collectivité / Institutionnel"},
    {label: "Média de sorties", value: "Média de sorties"},
    {label: "Autre", value: "Autre"}
]

export const ACCOUNT_TYPE_LIST = [
    "DISTRIBUTEUR","DIFFUSEUR","ORGANISATEUR"
]
export const AVAILABLE_PLANS =[
    "TRIAL","PREMIUM","STANDARD"
]