import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {VenueInterface} from "../../@interfaces/VenueInterface";
import {Badge, Button, Form, Table} from "react-bootstrap";
import {Tag} from "react-tag-input/types/components/SingleTag";
import {formatDateTimeWithTimeZone} from "../../utils/dateFormatter";


interface VenueListViewProps {
    venues: VenueInterface[];
}

function VenueListView({venues}: VenueListViewProps) {
    const {t,i18n} = useTranslation();
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(venues.map(venue => false));

    useEffect(() => {
        setSelectedCheckboxes(venues.map(() => false));
        setSelectedAll(false);
    }, [venues]);
    const handleSelectAll = () => {
        const newSelectedAll = !selectedAll;
        setSelectedAll(newSelectedAll);
        setSelectedCheckboxes(venues.map(() => newSelectedAll));
    };

    // this is used to handle simple select
    const handleSelectCheckbox = (index: any) => {
        const newSelectedCheckboxes = [...selectedCheckboxes];
        newSelectedCheckboxes[index] = !newSelectedCheckboxes[index];
        setSelectedCheckboxes(newSelectedCheckboxes);
        setSelectedAll(newSelectedCheckboxes.every(selected => selected));
    };
    return (<>
        {<Table striped responsive>
            <thead>
            <tr>
                <th>#</th>
                <th>
                    <Form.Check
                        type="checkbox"
                        id={`select-all-checkbox`}
                        checked={selectedAll}
                        onChange={handleSelectAll}
                    />
                </th>
                <th>{t('common.name')}</th>
                <th>{t('common.address')}</th>
                <th>{t('common.city')}</th>
                <th>{t('common.postalCode')}</th>
                <th>{t('common.country')}</th>
                <th>{t('common.tags')}</th>
                <th>{t('common.owner')}</th>
                <th>{t('common.createdAt')}</th>
                <th>{t('common.updatedAt')}</th>
                {/*<th>{t('common.action')}</th>*/}
            </tr>
            </thead>
            <tbody>

            {venues.map((venue: VenueInterface, index) => (
                <tr key={venue.id}>
                    <td>{index + 1}</td>
                    <td>
                        <Form.Check // prettier-ignore
                            type="checkbox"
                            id={`checkbox-${venue.id}`}
                            checked={selectedCheckboxes[index] || false}
                            onChange={() => handleSelectCheckbox(index)}
                        />
                    </td>
                    <td>{venue.name}</td>
                    <td>{venue.address?.address}</td>
                    <td>{venue.address?.city}</td>
                    <td>{venue.address?.postal_code}</td>
                    <td>{venue.address?.country}</td>
                    <td className="justify-content-between overflow-y-auto">{venue.tags?.map((tag) =>
                        <Badge bg="secondary mx-1" key={tag.id}>{tag.name}</Badge>
                    )}</td>
                    <td></td>
                    <td>{formatDateTimeWithTimeZone(venue.created_at, `${i18n.resolvedLanguage}-${i18n.resolvedLanguage?.toUpperCase()}`)}</td>
                    <td>TO DO</td>
                    {/*<td className="justify-content-around">
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-eye-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-pencil-fill"></i>
                        </Button>
                        <Button variant="outline-primary" size="sm">
                            <i className="bi bi-trash"></i>
                        </Button>
                    </td>*/}
                </tr>
            ))
            }
            </tbody>
        </Table>}
    </>)
}

export default VenueListView;