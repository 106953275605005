import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";

import {PaginationHeader} from "../../@interfaces/PaginationHeader";

import LoadingComponent from "../../components/LoadingComponent/LoadingComponent";


import VenuesService from "../../services/VenuesService";
import VenuesFilters from "../../components/VenuesFilters/VenuesFilters";
import VenueListView from "../../views/VenueListView/VenueListView";
import {VenueInterface} from "../../@interfaces/VenueInterface";
import {parseResponseHeaders} from "../../utils/ResponseWrapper";
import PaginationWithPageSizeSelector from "../../components/Pagination/PaginationWithSizeSelectorComponent";
import AuthenticationService from "../../services/AuthenticationService";


interface VenuesPerPage {
    page: number;
    items: VenueInterface[];
}

export function VenuesListPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [venues, setVenues] = useState<VenuesPerPage[]>([]);
    const [currentPageVenuesData, setCurrentPageVenuesDatas] = useState<VenueInterface[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber: number) => {
        if (pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            const pageData = getCurrentPageVenues(pageNumber);
            if (pageData.items.length > 0) {
                setCurrentPageVenues(pageNumber);
            } else {
                fetchVenues({currentPage: pageNumber, pageSize: itemsPerPage});
            }
        }
    };

    const getCurrentPageVenues = (pageNumber: number): VenuesPerPage => {

        return venues.find(element => element.page === pageNumber) || {page: pageNumber, items: []};
    };

    const setCurrentPageVenues = (pageNumber: number) => {
        try {
            const venuesPage = getCurrentPageVenues(pageNumber);

            setCurrentPageVenuesDatas(venuesPage.items)
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const handlePageSizeChange = (event: any) => {
        try {
            setItemsPerPage(Number(event.target.value));
            setVenues([]);
            fetchVenues({currentPage: 1, pageSize: Number(event.target.value)});
        } catch (e: any) {
            console.error('error on line ', e);
        }

    };

    const applyFilters = (values: any) => {
        try {
            fetchVenues({currentPage: 1, pageSize: itemsPerPage}, values);
        } catch (e: any) {
            console.error('error on line ', e);
        }
    }


    const fetchVenues = async (pagingData: PaginationHeader = {
        currentPage: 1,
        pageSize: itemsPerPage
    }, filter = {}) => {
        try {

            setIsLoading(true);
            const response = await VenuesService.retrieveVenues({pagination: pagingData, filters: filter});

            const {total_pages, page_size, total_count} = parseResponseHeaders(response.headers);
            setTotalPages(total_pages);
            setItemsPerPage(page_size);
            setTotalCount(total_count);
            setVenues(prevData => [...prevData, {
                page: Number.isNaN(Number(response.headers.page)) ? 0 : Number(response.headers.page),
                items: response.data
            }]);
            setCurrentPageVenuesDatas(response.data)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching events venues:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {

        //fetchVenues();
          const login = async () => {
            try {
                await AuthenticationService.login({
                    identifier: process.env.REACT_APP_API_IDENTIFIER ?? "",
                    secret: process.env.REACT_APP_API_SECRET ?? ""
                });
            } catch (error) {
                console.error('Error logging in:', error);
            }
        };

        login();
    }, []);

    return (
        <>
            {isLoading && (
                <LoadingComponent/>
            )}
            <VenuesFilters setFiltersValue={applyFilters}/>
            <PaginationWithPageSizeSelector itemsPerPage={itemsPerPage} totalCount={totalCount}
                                            currentPage={currentPage} totalPages={totalPages}
                                            handlePageChange={handlePageChange}
                                            handlePageSizeChange={handlePageSizeChange}/>

            <VenueListView venues={currentPageVenuesData}/>

        </>
    );
}

export default VenuesListPage;